.ListeningVideo {
    max-width: 360px;
    border-bottom: 2px solid black;
    padding-bottom: 6px
}
.ListeningAudio {
    max-width: 260px;
    border-bottom: 2px solid black;
    padding-bottom: 6px
}

