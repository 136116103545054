.musicimages {
    height: 245px;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
}
.pagefooter {
    float: left;
}
.pagewrapper {
    min-height: calc(100vh -460px);
    padding-top: 30px
}
